// Dependencies
import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import useStores from '../src/helpers/useStores';
import {
	Page,
	NavBar,
	Footer,
	LoginForm,
	ErrorMessage,
	Main,
} from '../src/components';
import '../styles/index.scss';

// Data
import links from '../data/navbar-links';

const LoginPage = observer(() => {
	const { errorStore, sessionStore } = useStores();

	useEffect(() => {
		(async () => {
			await sessionStore.load();
		})();
	}, []);

	// For some reason, this triggers the load
	sessionStore.hasLoaded;
	sessionStore.session;

	return (
		<Page>
			<NavBar links={links} />
			<Main>
				<ErrorMessage
					error={errorStore.error}
					onClose={errorStore.clear}
				/>
				<LoginForm {...{ errorStore, sessionStore }} />
			</Main>
			<Footer />
		</Page>
	);
});

export default LoginPage;
